/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Container, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import logoSVG from '../../../../../../src/icons/Logo.svg';
import cx from 'classnames';

import { Anontools, Logo, Navigation } from '@plone/volto/components';

import { Personaltools, AnimatedSearchWidget } from '../../../../../components';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    isEditMode: PropTypes.bool,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  state = {
    isSearchFocused: false,
    isScrolled: false,
  };

  componentDidMount() {
    var element = document.getElementById('main').offsetHeight;
    if (element > 500 && !this.props.isEditMode) {
      window.onscroll = () => {
        window.scrollY > 123
          ? this.setState({ isScrolled: true })
          : this.setState({ isScrolled: false });
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isEditMode) {
      window.onscroll = () => {};
    } else if (!this.props.isEditMode) {
      window.onscroll = () => {
        window.scrollY > 123
          ? this.setState({ isScrolled: true })
          : this.setState({ isScrolled: false });
      };
    }
  }

  onSearchFocusToggle = () => {
    this.setState({ isSearchFocused: !this.state.isSearchFocused });
  };
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Segment
        basic
        clearing
        className={cx('header', {
          scrolled: this.state.isScrolled,
          sidebarOpen: this.props.token,
          edit: this.props.isEditMode,
        })}
      >
        <Container id="header-wrapper" className="header-wrapper">
          <div className="upper nav">
            <Segment basic className="logo">
              {!this.state.isScrolled ? (
                <Logo />
              ) : (
                <Link to="/">
                  <Icon name={logoSVG} className="mini-logo" size="50px" />
                </Link>
              )}
            </Segment>

            {!this.props.token && (
              <Segment
                basic
                className="anontools tablet computer large screen widescreen only"
              >
                <Anontools />
              </Segment>
            )}
            {this.props.token && (
              <Segment
                basic
                className="anontools tablet computer large screen widescreen only"
              >
                <Personaltools />
              </Segment>
            )}
          </div>
          <div className="lower nav">
            {this.state.isScrolled && (
              <div className="mini logo wrapper">
                <Link to="/">
                  <Icon name={logoSVG} className="mini-logo" size="50px" />
                </Link>
              </div>
            )}
            <Segment basic className="navigation">
              <Navigation
                pathname={this.props.pathname}
                isSearchFocused={this.state.isSearchFocused}
              />
            </Segment>
            <Segment
              basic
              className="searchwidget tablet computer large screen widescreen only"
            >
              <AnimatedSearchWidget
                pathname={this.props.pathname}
                onSearchFocusToggle={this.onSearchFocusToggle}
              />
            </Segment>
          </div>
        </Container>
      </Segment>
    );
  }
}

export default connect((state) => ({
  token: state.userSession.token,
}))(Header);

/*
<Segment basic>
  <Container>
    <Grid stackable>
      <Grid.Row className="header">
        <Grid.Column width={2} className="logo">
          <Logo />
        </Grid.Column>
        <Grid.Column
          width={this.props.token ? 7 : 6}
          className="navigation"
        >
          <Navigation pathname={this.props.pathname} />
        </Grid.Column>
        {!this.props.token && (
          <Grid.Column width={1} className="tools">
            <Anontools />
          </Grid.Column>
        )}
        <Grid.Column width={3} className="search">
          <SearchWidget pathname={this.props.pathname} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
</Segment>
*/
