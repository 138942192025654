import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { getContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';

// import IconArrowRightPNG from './icon-arrow-right.png';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const HighlightBody = ({ data, id, isEditMode, intl }) => {
  const contentSubrequests = useSelector((state) => state.content.subrequests);
  const dispatch = useDispatch();
  const result = contentSubrequests?.[id]?.data;
  const image = result?.image
    ? result.image?.scales?.teaser?.download
    : result?.preview_image
    ? result.preview_image?.scales?.teaser?.download
    : null;
  const align = data?.align_left ? 'left' : 'right';

  React.useEffect(() => {
    if (data.href) {
      dispatch(getContent(flattenToAppURL(data.href), null, id));
    }
  }, [dispatch, data, id]);

  return (
    <div className={`teaser-inner ${align} align`}>
      {!data.href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {data.href && result && (
        <div>
          {!isEditMode ? (
            <Link
              to={flattenToAppURL(data.href)}
              target={data.openLinkInNewTab && '_blank'}
            >
              <div className="image-wrapper">
                {image && (
                  <img
                    className="teaser-image"
                    src={flattenToAppURL(image)}
                    alt=""
                    loading="lazy"
                  />
                )}
                {data?.credit && (
                  <div
                    className="credits"
                    dangerouslySetInnerHTML={{ __html: data?.credit?.data }}
                  />
                )}
              </div>
              <div className="text">
                <h3>{data.title}</h3>
                <div className="description">
                  <p>{data.description}</p>
                </div>
              </div>
            </Link>
          ) : (
            <>
              <div className="image-wrapper">
                {image && (
                  <img
                    className="teaser-image"
                    src={flattenToAppURL(image)}
                    alt=""
                    loading="lazy"
                  />
                )}
                {data?.credit && (
                  <div
                    className="credits"
                    dangerouslySetInnerHTML={{ __html: data?.credit?.data }}
                  />
                )}
              </div>
              <div className="text">
                <h3>{data.title}</h3>
                <div className="description">
                  <p>{data.description}</p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

HighlightBody.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default injectIntl(HighlightBody);
