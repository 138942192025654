import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import TeaserHeroSidebar from './TeaserHeroSidebar';
import HighlightBody from './HighlightBody';

const SingleTeaserEdit = ({
  data,
  onChangeBlock,
  block,
  id,
  selected,
  properties,
  render,
  sidebarData,
}) => {
  return (
    <>
      <HighlightBody data={data} id={id} isEditMode />
      <SidebarPortal selected={selected}>
        <TeaserHeroSidebar
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
          sidebarData={sidebarData}
        />
      </SidebarPortal>
    </>
  );
};

export default SingleTeaserEdit;
