import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import circles from '../../icons/circles-newsletter.svg';
import briefumschlag from '../../icons/briefumschlag.svg';

/**
 * NewsletterSnippet container class.
 * @class NewsletterSnippet
 * @extends Component
 */
export default class NewsletterSnippet extends Component {
  state = { email: '' };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    // const { email } = this.state;
    const win = window.open(
      `https://seu1.cleverreach.com/f/74938-224009/`,
      '_blank',
    );
    win.focus();
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    // const { email } = this.state;
    return (
      <div className="newsletter-form ui container">
        <div className="left">
          <h2>Möchten Sie unseren Newsletter erhalten?</h2>
          <Form className="tablet or lower hidden" onSubmit={this.handleSubmit}>
            <Form.Group>
              {/* <Form.Input
              placeholder="Email"
              name="email"
              value={email}
              onChange={this.handleChange}
            /> */}
              <Button
                content="Jetzt abonnieren"
                icon="newspaper"
                labelPosition="left"
                color="blue"
              />
            </Form.Group>
          </Form>
          <Form
            style={{ width: '95%' }}
            className="tablet mobile only"
            onSubmit={this.handleSubmit}
          >
            {/* <Form.Input
            placeholder="Email"
            name="email"
            value={email}
            onChange={this.handleChange}
          /> */}
            <Button
              className="branded fontawesome"
              content="Jetzt abonnieren"
              icon="newspaper"
              labelPosition="left"
              color="blue"
              size="large"
            />
          </Form>
        </div>
        <div className="right">
          <Icon className="circles-deco" size="200px" name={circles} />
          <Icon className="briefumschlag" size="90px" name={briefumschlag} />
        </div>
      </div>
    );
  }
}
