import React from 'react';
import { Icon } from '@plone/volto/components';
import circles from '../../icons/circles-akteure.svg';

const View = ({ data }) => {
  return (
    <div className="block akteure">
      <div className="background">
        <div className="description-wrapper">
          <p>{data?.description}</p>
        </div>
        <div className="main">
          {data?.url && (
            <div className="image-wrapper">
              <img src={`${data.url}/@@images/image/teaser`} alt="Profilbild" />
            </div>
          )}
          <div className="info">
            <h4>{data?.akteur_title}</h4>
            <span className="">{data?.akteur_description}</span>
          </div>
        </div>
        <div className="background-circle">
          <Icon className="circles-deco" size="520px" name={circles} />
        </div>
      </div>
    </div>
  );
};

export default View;
