/**
 * @module components/theme/Unauthorized/Unauthorized
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';

/**
 * unauthorized function.
 * @function Unauthorized
 * @returns {string} Markup of the unauthorized page.
 */
const Unauthorized = () => {
  return (
    <Container className="view-wrapper">
      <h1>
        Die Seite, die Sie öffnen möchten, existiert leider nicht mehr. Wir
        bitten um Entschuldigung.
      </h1>
      <p>
        <Link to="/">zur Startseite</Link>
      </p>
    </Container>
  );
};

export default withServerErrorCode(401)(Unauthorized);
