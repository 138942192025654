/**
 * OVERRIDE schema.js
 * REASON: Add title and description fields to Video block
 * FILE: https://github.com/plone/volto/blob/6cb3cfab77e9ddc9e32dc29dd45652881db91542/src/components/manage/Blocks/Video/schema.js
 * FILE VERSION: Volto 16.2.0
 * DATE: 2025-03-05
 * DEVELOPER: @danalvrz
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Video: {
    id: 'Video',
    defaultMessage: 'Video',
  },
  VideoURL: {
    id: 'Video URL',
    defaultMessage: 'Video URL',
  },
  Preview_image: {
    id: 'Preview Image URL',
    defaultMessage: 'Preview Image URL',
  },
  Alignment: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  Title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  Description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
});
export const VideoBlockSchema = (props) => ({
  title: props.intl.formatMessage(messages.Video),
  block: 'Video',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['url', 'preview_image', 'align', 'title', 'description'],
    },
  ],

  properties: {
    url: {
      title: props.intl.formatMessage(messages.VideoURL),
      widget: 'url',
    },
    // START CUSTOMIZATION
    title: {
      title: props.intl.formatMessage(messages.Title),
    },
    description: {
      title: props.intl.formatMessage(messages.Description),
    },
    // END CUSTOMIZATION
    preview_image: {
      title: props.intl.formatMessage(messages.Preview_image),
      widget: 'url',
    },
    align: {
      title: props.intl.formatMessage(messages.Alignment),
      widget: 'align',
    },
  },
  required: [],
});

export default VideoBlockSchema;
