import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';

import { flattenToAppURL } from '@plone/volto/helpers';

// const messages = defineMessages({
//   PleaseChooseContent: {
//     id: 'Please choose an existing content as source for this element',
//     defaultMessage:
//       'Please choose an existing content as source for this element',
//   },
//   moreInfo: {
//     id: 'moreInfo',
//     defaultMessage: 'More info',
//   },
// });

const SliderBody = (props) => {
  const { data, isEditMode, frontpageTitle } = props;

  return (
    <div className="grid-teaser-item top">
      {frontpageTitle && (
        <div className="main-title">
          <h1>
            <span className="first-line">
              Das Online-Portal für Ehrenamtliche
            </span>
            <div />
            <span className="second-line">in Grundbildung und Integration</span>
          </h1>
        </div>
      )}
      {data && (
        <div className="teaser-item top">
          {(() => {
            const item = (
              <>
                {data?.preview_image && (
                  <div className="highlight-image-wrapper">
                    <img
                      src={flattenToAppURL(data.preview_image.download)}
                      alt=""
                      loading="lazy"
                    />
                  </div>
                )}
                <div className="teaser-item-title fix-width-issue">
                  {/* <div className="supertitle">{result['@type']}</div> */}
                  <h3>{data.nav_title || data.title}</h3>
                  <p>{data.description}</p>
                  <Button
                    as={Link}
                    to={flattenToAppURL(data?.href)}
                    className="branded"
                  >
                    Mehr zum Thema
                  </Button>
                </div>
                <div
                  className="credits"
                  dangerouslySetInnerHTML={{ __html: data?.credit?.data }}
                />
              </>
            );
            if (!isEditMode && data?.href) {
              return <Link to={flattenToAppURL(data.href)}>{item}</Link>;
            } else {
              return item;
            }
          })()}
        </div>
      )}
    </div>
  );
};

export default injectIntl(SliderBody);
