/**
 * OVERRIDE ImageData.jsx
 * REASON: Add credit attribute
 * FILE: https://github.com/kitconcept/volto-blocks/blob/5.x.x/src/components/ImagesGrid/ImageData.jsx
 * FILE VERSION: volto-blocks 5.0.0
 * DATE: 2025-02-20
 * DEVELOPER: @danalvrz
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import config from '@plone/volto/registry';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import { SchemaRenderer } from '@kitconcept/volto-blocks/components';
import { ImagesGridSchema } from '@kitconcept/volto-blocks/components/ImagesGrid/schema';
import { getContent } from '@plone/volto/actions';
import { useDispatch } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';

const ImageData = (props) => {
  const { data, dataGrid, block, onChangeBlock } = props;
  const dispatch = useDispatch();
  const prevDataHref = React.useRef(data?.url);

  const intl = useIntl();
  // START CUSTOMIZATION
  React.useEffect(() => {
    if (data.url && data.url !== prevDataHref.current) {
      dispatch(getContent(flattenToAppURL(data.url), null, data.id)).then(
        (resp) => {
          onChangeBlock(data.id, {
            ...data,
            credit: resp?.credit,
          });
        },
      );
    }
    prevDataHref.current = data.url;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.url]);
  // END CUSTOMIZATION

  const schema = ImagesGridSchema({ ...props, intl });

  const applyVariationSchemaExtender = (schema) => {
    const variations =
      config.blocks?.blocksConfig?.[dataGrid['@type']]?.variations;

    const schemaExtender =
      variations?.[dataGrid?.variation]?.['schemaExtenderItem'];

    if (schemaExtender) {
      return schemaExtender(schema, props, intl);
    } else {
      return schema;
    }
  };

  return (
    <SchemaRenderer
      schema={applyVariationSchemaExtender(schema)}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      fieldIndex={data.index}
    />
  );
};

ImageData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default withObjectBrowser(ImageData);
