/**
 * SprachmodulView view component.
 * @module components/theme/View/SprachmodulView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Container, Image, Icon } from 'semantic-ui-react';
import ReactGA from 'react-ga4';

import ListingView from './ListingView';
import SprachmodulList from './Listings/SprachmodulList';
import SprachmodulContent from './Listings/SprachmodulContent';

/**
 * Listing view component function.
 * @function SprachmodulView
 * @param {object} props Content
 * @returns {string} The component
 */
const SprachmodulView = (props) => (
  <Container className="had-portlets">
    <div className="sprachmodul-wrapper">
      <ListingView
        {...props}
        listingView={SprachmodulList}
        contentView={SprachmodulContent}
      />
      <div className="sprachmodul-button">
        {props.content.pdf_file && (
          <Button
            className="branded"
            primary
            icon
            size="large"
            labelPosition="left"
            href={props.content.pdf_file.download}
            as="a"
            onClick={() =>
              ReactGA.event({
                category: 'Download',
                action: 'file_download',
                label: props.content.pdf_file.download,
              })
            }
          >
            <Icon className="fontawesome" name="file pdf outline" />
            <Button.Content>Sprachmodul herunterladen</Button.Content>
          </Button>
        )}
      </div>
    </div>
  </Container>
);

SprachmodulView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    pdf_file: PropTypes.object,
    portlet_image_1: PropTypes.object,
    portlet_link_1: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        description: PropTypes.string,
        review_state: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default SprachmodulView;
