import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getTeaserImageURL } from './utils';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserWithDetails = ({ data, dataBlock, isEditMode }) => {
  const intl = useIntl();
  return (
    <>
      {!data.href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {data.href && (
        <>
          {(() => {
            const item = (
              <div
                className={cx('grid-teaser-item top', {
                  withDescription: data?.description,
                })}
              >
                <div className="teaser-item-content">
                  {data?.preview_image && (
                    <div className="grid-image-wrapper">
                      <img
                        src={getTeaserImageURL(data)}
                        alt=""
                        loading="lazy"
                      />
                      {data?.credit && (
                        <div
                          className="credits"
                          dangerouslySetInnerHTML={{
                            __html: data?.credit?.data,
                          }}
                        />
                      )}
                    </div>
                  )}
                  <h3>{data?.title}</h3>
                  {!dataBlock.hide_description && data?.description && (
                    <p>{data?.description}</p>
                  )}
                </div>
              </div>
            );

            if (!isEditMode) {
              return (
                <Link
                  to={flattenToAppURL(data.href)}
                  target={data.openLinkInNewTab ? '_blank' : null}
                >
                  {item}
                </Link>
              );
            } else {
              return item;
            }
          })()}
        </>
      )}
    </>
  );
};

TeaserWithDetails.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserWithDetails;
