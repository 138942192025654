import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, FormattedDate } from '@plone/volto/components';

const DefaultTemplate = ({ items, isEditMode }) => {
  const day = {
    day: 'numeric',
  };
  const format = {
    month: 'short',
    year: 'numeric',
  };
  const dayMonth = {
    day: 'numeric',
    month: 'numeric',
  };

  const formatter = new Intl.DateTimeFormat('default', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  const formattedDate = (date) => formatter.format(date);

  return (
    <>
      <div className="items">
        {items.map((item) => {
          if (item.start && item.end && item?.['@type'] === 'Event') {
            const end = new Date(item.end);
            const start = new Date(item.start);
            const isMultipleDays = start.getDate() < end.getDate();
            return (
              <div className="listing-item" key={item['@id']}>
                <div
                  className={`date-container 
                  ${item?.['@type'] === 'Event'}`}
                >
                  {isMultipleDays ? (
                    <>
                      <span className="days">
                        <FormattedDate date={item.start} format={day} />
                        {'-'}
                        <FormattedDate date={item.end} format={day} />
                      </span>
                    </>
                  ) : (
                    <>
                      <FormattedDate
                        date={item.start}
                        format={day}
                        className="day"
                      />
                    </>
                  )}
                  <FormattedDate date={item.start} format={format} />
                </div>
                <ConditionalLink item={item} condition={!isEditMode}>
                  <div className="listing-body">
                    {isMultipleDays ? (
                      <>
                        <span className="days">
                          <FormattedDate date={item.start} format={dayMonth} />
                          {' - '}
                          <FormattedDate date={item.end} />,
                        </span>
                        <span className="head_title">
                          <span>{formattedDate(start)}</span>
                          UHR BIS <span>{formattedDate(end)}</span>
                          UHR
                        </span>
                      </>
                    ) : (
                      <>
                        <div className="head_title">
                          <FormattedDate
                            date={item.start}
                            includeTime
                            locale="de"
                          />{' '}
                          UHR BIS <span>{formattedDate(end)}</span>
                          UHR
                        </div>
                      </>
                    )}

                    {item?.head_title && <span>{item?.head_title}</span>}
                    <h2>{item.title ?? item.id}</h2>
                  </div>
                </ConditionalLink>
              </div>
            );
          }

          return (
            <ConditionalLink item={item} condition={!isEditMode}>
              <div className="listing-item" key={item['@id']}>
                <div className="listing-body">
                  {item?.head_title && <span>{item?.head_title}</span>}
                  <h2>{item.title ?? item.id}</h2>
                </div>
              </div>
            </ConditionalLink>
          );
        })}
      </div>
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
