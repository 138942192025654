/**
 * OVERRIDE TeaserDefaultTemplate.jsx
 * REASON: Add credit attribute
 * FILE: https://github.com/kitconcept/volto-blocks/blob/5.x.x/src/components/TeaserGrid/TeaserDefaultTemplate.jsx
 * FILE VERSION: volto-blocks 5.0.0
 * DATE: 2025-02-14
 * DEVELOPER: @danalvrz
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getTeaserImageURL } from '@kitconcept/volto-blocks/components/TeaserGrid/utils.js';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserDefaultTemplate = ({ data, dataBlock, isEditMode }) => {
  const intl = useIntl();

  return (
    <>
      {!data.href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {data.href && (
        <div className="grid-teaser-item top">
          {(() => {
            const item = (
              <>
                {data?.preview_image && (
                  <div className="grid-image-wrapper">
                    <img
                      src={flattenToAppURL(getTeaserImageURL(data))}
                      alt=""
                      loading="lazy"
                    />
                    {/*START CUSTOMIZATION*/}
                    <div
                      className="credits"
                      dangerouslySetInnerHTML={{ __html: data?.credit?.data }}
                    />
                    {/*END CUSTOMIZATION*/}
                  </div>
                )}
                <h3>{data?.title}</h3>
                {!dataBlock.hide_description && data?.description && (
                  <p>{data?.description}</p>
                )}
              </>
            );
            if (!isEditMode) {
              return (
                <Link
                  to={flattenToAppURL(data.href)}
                  target={data.openLinkInNewTab ? '_blank' : null}
                >
                  {item}
                </Link>
              );
            } else {
              return item;
            }
          })()}
        </div>
      )}
    </>
  );
};

TeaserDefaultTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserDefaultTemplate;
