import React from 'react';
import HighlightBody from './HighlightBody';

const SingleTeaserView = ({ data, id, render }) => {
  const align = data?.align_left ? 'left' : 'right';
  const teaserHeroBodyRender = render ? (
    <>{render(data, id)}</>
  ) : (
    <HighlightBody data={data} id={id} />
  );
  return (
    <div className={`block singleteaser ${align} align`}>
      {' '}
      {teaserHeroBodyRender}
    </div>
  );
};

export default SingleTeaserView;
