import React from 'react';
import View from './View';

const Edit = (props) => {
  return (
    <>
      <View />
    </>
  );
};

export default Edit;
