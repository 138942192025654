/**
 * OVERRIDE Body.jsx
 * REASON: Modify video block to only be displayed when respective cookies are accepted
 * FILE: https://github.com/plone/volto/blob/6cb3cfab77e9ddc9e32dc29dd45652881db91542/src/components/manage/Blocks/Video/Body.jsx
 * FILE VERSION: Volto 16.2.0
 * DATE: 2021-01-11
 * DEVELOPER: @jackahl
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

/**
 * Body video block.
 * @module components/manage/Blocks/Video/Body
 */

import React from 'react';
import PropTypes from 'prop-types';
// START CUSTOMIZATION
import { Embed, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { withCookies } from 'react-cookie';
import cx from 'classnames';
import {
  isInternalURL,
  getParentUrl,
  flattenToAppURL,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { updateCookieConsent } from '@package/actions';

/**
 * View video block class.
 * @class View
 * @extends Component
 */
const View = ({ data, cookies }) => {
  const dispatch = useDispatch();
  const youtubeConsent = useSelector((state) => state.cookieConsent.youtube);
  const vimeoConsent = useSelector((state) => state.cookieConsent.vimeo);

  const enableYoutube = () => {
    let expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    cookies.set('confirm_youtube', 1, {
      path: '/',
      expires: expiryDate,
    });
    dispatch(updateCookieConsent({ youtube: true }));
  };

  const enableVimeo = () => {
    let expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    cookies.set('confirm_vimeo', 1, {
      path: '/',
      expires: expiryDate,
    });
    dispatch(updateCookieConsent({ vimeo: true }));
  };

  return (
    <div
      className={cx(
        'block video align',
        {
          center: !Boolean(data.align),
        },
        data.align,
      )}
    >
      {data.url && (
        <div
          className={cx('video-inner', {
            'full-width': data.align === 'full',
          })}
        >
          {data.url.match('youtu') ? (
            youtubeConsent ? (
              <>
                {data.url.match('list') ? (
                  data.preview_image ? (
                    <Embed
                      url={`https://www.youtube.com/embed/videoseries?list=${
                        data.url.match(/^.*\?list=(.*)$/)[1]
                      }`}
                      placeholder={
                        isInternalURL(data.preview_image)
                          ? `${flattenToAppURL(
                              data.preview_image,
                            )}/@@images/image`
                          : data.preview_image
                      }
                      defaultActive
                      autoplay={false}
                    />
                  ) : (
                    <Embed
                      url={`https://www.youtube.com/embed/videoseries?list=${
                        data.url.match(/^.*\?list=(.*)$/)[1]
                      }`}
                      icon="play"
                      defaultActive
                      autoplay={false}
                    />
                  )
                ) : data.preview_image ? (
                  <Embed
                    id={
                      data.url.match(/.be\//)
                        ? data.url.match(/^.*\.be\/(.*)/)[1]
                        : data.url.match(/^.*\?v=(.*)$/)[1]
                    }
                    source="youtube"
                    placeholder={
                      isInternalURL(data.preview_image)
                        ? `${flattenToAppURL(
                            data.preview_image,
                          )}/@@images/image`
                        : data.preview_image
                    }
                    icon="play"
                    autoplay={false}
                  />
                ) : (
                  <Embed
                    id={
                      data.url.match(/.be\//)
                        ? data.url.match(/^.*\.be\/(.*)/)[1]
                        : data.url.match(/^.*\?v=(.*)$/)[1]
                    }
                    source="youtube"
                    icon="play"
                    defaultActive
                    autoplay={false}
                  />
                )}
              </>
            ) : (
              <div class="youtube-placeholder">
                {data.preview_image && (
                  <img
                    src={
                      isInternalURL(data.preview_image)
                        ? `${flattenToAppURL(
                            data.preview_image,
                          )}/@@images/image`
                        : data.preview_image
                    }
                    alt={data?.title ?? ''}
                  ></img>
                )}
                <div className="video-info">
                  {data?.title && <p className="title">{data?.title}</p>}
                  {data?.description && (
                    <span className="video-description">
                      {' '}
                      {data.description}
                    </span>
                  )}
                </div>
                <div className="consent-message">
                  <p>
                    Für die Wiedergabe dieses Videos auf YouTube.com ist Ihre
                    Zustimmung zur Speicherung von Daten (“Cookies”)
                    erforderlich. Ihre aktuellen Einstellungen zur Speicherung
                    von Daten können Sie jederzeit unter “Datenschutz” einsehen
                    und verändern.
                  </p>

                  <Button
                    className="branded blue"
                    onClick={() => enableYoutube()}
                  >
                    Zustimmen und Video anzeigen
                  </Button>
                </div>
              </div>
            )
          ) : (
            <>
              {data.url.match('vimeo') ? (
                vimeoConsent ? (
                  data.preview_image ? (
                    <Embed
                      id={data.url.match(/^.*\.com\/(.*)/)[1]}
                      source="vimeo"
                      placeholder={
                        isInternalURL(data.preview_image)
                          ? `${flattenToAppURL(
                              data.preview_image,
                            )}/@@images/image`
                          : data.preview_image
                      }
                      icon="play"
                      autoplay={false}
                    />
                  ) : (
                    <Embed
                      id={data.url.match(/^.*\.com\/(.*)/)[1]}
                      source="vimeo"
                      icon="play"
                      defaultActive
                      autoplay={false}
                    />
                  )
                ) : (
                  <div class="vimeo-placeholder">
                    {data.preview_image && (
                      <img
                        src={
                          isInternalURL(data.preview_image)
                            ? `${flattenToAppURL(
                                data.preview_image,
                              )}/@@images/image`
                            : data.preview_image
                        }
                        alt={data?.title ?? ''}
                      ></img>
                    )}
                    <div className="video-info">
                      {data?.title && <p className="title">{data?.title}</p>}
                      {data?.description && (
                        <span className="video-description">
                          {' '}
                          {data.description}
                        </span>
                      )}
                    </div>
                    <div className="consent-message">
                      <p>
                        Für die Wiedergabe dieses Videos auf Vimeo.com ist Ihre
                        Zustimmung zur Speicherung von Daten (“Cookies”)
                        erforderlich. Ihre aktuellen Einstellungen zur
                        Speicherung von Daten können Sie jederzeit unter
                        “Datenschutz” einsehen und verändern.
                      </p>

                      <Button
                        className="branded blue"
                        onClick={() => enableVimeo()}
                      >
                        Zustimmen und Video anzeigen
                      </Button>
                    </div>
                  </div>
                )
              ) : (
                <>
                  {data.url.match('.mp4') ? (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video
                      src={
                        isInternalURL(
                          data.url.replace(
                            getParentUrl(config.settings.apiPath),
                            '',
                          ),
                        )
                          ? `${data.url}/@@download/file`
                          : data.url
                      }
                      controls
                      poster={
                        data.preview_image
                          ? isInternalURL(data.preview_image)
                            ? `${flattenToAppURL(
                                data.preview_image,
                              )}/@@images/image`
                            : data.preview_image
                          : ''
                      }
                      type="video/mp4"
                    />
                  ) : (
                    <div className="invalidVideoFormat" />
                  )}
                </>
              )}
            </>
          )}
          <figcaption className="video-info">
            {data?.title && <p className="title">{data?.title}</p>}
            {data?.description && (
              <span className="video-description"> {data.description}</span>
            )}
          </figcaption>
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withCookies(View);
// END CUSTOMIZATION
