/**
 * Youtube component.
 * @module components/theme/Youtube/Youtube
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
// import { ContentSwapper } from '../../components';
import { updateCookieConsent } from '@package/actions';

/**
 * Youtube component class.
 * @function Youtube
 * @param {string} url Url of the video
 * @returns {string} Markup of the component
 */

class Youtube extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    autoplay: PropTypes.bool,
    previewImage: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVideoPlaying: false,
    };
  }

  toggleVideoPlaying = () => {
    this.setState({ isVideoPlaying: this.state.isVideoPlaying ? false : true });
  };

  render = () => {
    const enableYoutube = () => {
      let expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      this.props.cookies.set('confirm_youtube', 1, {
        path: '/',
        expires: expiryDate,
      });
      this.props.updateCookieConsent({ youtube: true });
    };
    const url = this.props.url;
    const autoplay = false;
    const player = this.props.ytConsent ? (
      url.match('list') ? (
        <div className="ui active embed">
          <i aria-hidden="true" className="arrow right icon" />
          <div className="embed">
            <iframe
              frameBorder="0"
              height="100%"
              scrolling="no"
              src={`https://www.youtube.com/embed/videoseries?${
                autoplay ? 'autoplay=true&' : ''
              }list=${
                url.match(/^.*\?list=(.*)$/)
                  ? url.match(/^.*\?list=(.*)$/)[1]
                  : ''
              }`}
              width="100%"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="Embedded content from youtube"
            />
          </div>
        </div>
      ) : (
        <div className="ui active embed">
          <i aria-hidden="true" className="arrow right icon" />
          <div className="embed">
            <iframe
              frameBorder={0}
              height="100%"
              scrolling="no"
              src={`https://www.youtube.com/embed/${
                url.match(/.be\//)
                  ? url.match(/^.*\.be\/(.*)/)
                    ? url.match(/^.*\.be\/(.*)/)[1]
                    : ''
                  : url.match(/^.*\?v=(.*)$/)
                  ? url.match(/^.*\?v=(.*)$/)[1]
                  : ''
              }${autoplay ? '?autoplay=true&' : ''}`}
              width="100%"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="Embedded content from youtube."
            />
          </div>
        </div>
      )
    ) : (
      <div class="youtube-placeholder">
        {this.props.previewImage && (
          <img
            src={this.props.previewImage.download}
            alt={this.props.title ?? ''}
          ></img>
        )}
        {this.props.youtube_image && (
          <img
            src={this.props.youtube_image.download}
            alt={this.props.title ?? ''}
          ></img>
        )}
        <div className="video-info">
          {this.props.title && <p className="title">{this.props.title}</p>}
          {this.props.description && (
            <span className="video-description"> {this.props.description}</span>
          )}
        </div>
        <div className="consent-message">
          <p>
            Für die Wiedergabe dieses Videos auf YouTube.com ist Ihre Zustimmung
            zur Speicherung von Daten (“Cookies”) erforderlich. Ihre aktuellen
            Einstellungen zur Speicherung von Daten können Sie jederzeit unter
            “Datenschutz” einsehen und verändern.
          </p>

          <Button className="branded blue" onClick={() => enableYoutube()}>
            Zustimmen und Video anzeigen
          </Button>
        </div>
      </div>
    );

    // if (this.props.previewImage) {
    //   return (
    //     <div className="youtube-component">
    //       <ContentSwapper
    //         transitionName="cross-fade"
    //         transitionEnterTimeout={1000}
    //         transitionLeaveTimeout={1000}
    //         isVideoPlaying={this.state.isVideoPlaying}
    //         toggleVideoDisplay={this.toggleVideoPlaying}
    //       >
    //         <div className="youtube-preview">
    //           <img
    //             src={this.props.previewImage.download}
    //             className="youtube-preview-image"
    //             alt="Video Preview"
    //           />
    //         </div>
    //         {player}
    //       </ContentSwapper>
    //     </div>
    //   );
    // }
    return player;
  };
}

export default compose(
  connect(
    (state, props) => ({
      ytConsent: state.cookieConsent.youtube,
      youtube_image: state.content.data.youtube_image,
    }),
    { updateCookieConsent },
  ),
  withCookies,
)(Youtube);
