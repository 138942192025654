import React from 'react';

const View = ({ data }) => {
  return (
    <div className="block separat">
      <div className="line"></div>
    </div>
  );
};

export default View;
